import * as React from "react";
import {StockTable} from "./StockTable";


class MarketFeed extends React.Component {
    render() {
        return (

            <div className="itemBody">


                <div>
                    <StockTable/>

                </div>

            </div>

        )
    }
}

export default MarketFeed
;