import {StockList} from "./StockList";
import React, {Component} from 'react';

export class StockTable extends Component {


    constructor(props, context) {
        super(props, context);


        this.fields = [
            {field: "bid", label: "BID"},
            {field: "ask", label: "ASK"}
        ];

        this.items = [
            {
                subscription: "ticks|binanceifutures-binanceifutures|BTC-USD-240927-I-BTC-USD-PERP-I",
                market : 'Binance:BTC 27Sep24 - Binance:BTC PERP'
            },
            {
                subscription: "ticks|binanceifutures-deribit|BTC-USD-240927-I-BTC-USD-241227-I",
                market : 'Binance:BTC 27Sep24  - Deribit:BTC 27Dec24 '
            },
            {
                subscription: "ticks|deribit-deribit|BTC-USD-241227-I-BTC-USD-PERP-I",
                market : 'Deribit:BTC 27Dec24 - Deribit:BTC Perp '
            },
            {
                subscription: "ticks|binancefutures-deribit|BTC-USDT-PERP-F-BTC-USDC-PERP-F",
                market : 'Binance:BTC-USDT-PERP - Deribit:BTC-USDC-PERP'
            },
            {
                subscription: "ticks|deribit-huobifutures|BTC-USD-PERP-I-BTC-USD-240628-I",
                market : 'Deribit:BTC PERP - Huobi:BTC 28Jun24'
            },
            {
                subscription: "ticks|binanceifutures-huobifutures|BTC-USD-PERP-I-BTC-USD-240628-I",
                market : 'Binance:BTC-USDT PERP - Huobi:BTC 28Jun24'
            }
        ];


    }

    render() {
        return (<table className="table">
                <thead>
                <tr>
                    <th>Market</th>
                    {
                        this.fields.map(function (fieldDef) {
                            return (
                                <th className={"text-center "} key={fieldDef.field} data-field={fieldDef.field}>{fieldDef.label}</th>
                            );
                        })
                    }
                </tr>
                </thead>
                <StockList items={this.items} fields={this.fields}/>
            </table>
        )
    }

};

export default StockTable;