import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import MarketFeed from "../marketfeed/MarketFeed";

class IntegrationsTable extends React.Component {
    render() {
        return (


            <section className="hg_section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="kl-title-block text-center">
                                <h3 className="tbk__title fs-xxl fw-thin">
								<span className="fw-semibold fs-xxxl fs-xs-xl tcolor">
									Exchanges</span>
                                </h3>
                            </div>

                        </div>

                        <div className="col-sm-12 col-md-12">

                            <div className="grid-ibx grid-ibx--cols-3 grid-ibx--style-lined-center grid-ibx--hover-shadow">
                                <div className="grid-ibx__inner">
                                    <div className="grid-ibx__row clearfix">

                                        <div className="grid-ibx__item h-300">
                                            <div className="grid-ibx__item-inner">


                                                <div className="grid-ibx__icon-wrp">
                                                    <StaticImage
                                                        src="../../gatsbyimg/integrations/binancefutureswhite.png"
                                                        className="partners_carousel-img"
                                                        alt=""
                                                        width={"200"}
                                                        title=""/>

                                                </div>


                                                <div className="grid-ibx__desc-wrp">

                                                    <div className="services_box services_box--integrations sb--hasicon">
                                                        <div className="services_box__inner clearfix">
                                                            <div className="services_box__content">
                                                                <div className="services_box__list-wrapper">
                                                                    <span className="services_box__list-bg"></span>
                                                                    <ul className="services_box__list">
                                                                        <li>
                                                                            <span className="services_box__list-text">Futures trading</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="services_box__list-text">Spot trading</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>


                                        <div className="grid-ibx__item h-300">
                                            <div className="grid-ibx__item-inner">


                                                <div className="grid-ibx__icon-wrp">
                                                    <StaticImage src="../../gatsbyimg/integrations/huobi-white.png"
                                                                 className="partners_carousel-img"
                                                                 alt=""
                                                                 width={"200"}
                                                                 title=""/>
                                                </div>


                                                <div className="grid-ibx__desc-wrp">

                                                    <div className="services_box services_box--integrations sb--hasicon">
                                                        <div className="services_box__inner clearfix">
                                                            <div className="services_box__content">
                                                                <div className="services_box__list-wrapper">
                                                                    <span className="services_box__list-bg"></span>
                                                                    <ul className="services_box__list">
                                                                        <li>
                                                                            <span className="services_box__list-text">Futures trading</span>
                                                                        </li>
                                                                        <li>
                                                                            <span className="services_box__list-text">Spot trading</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>


                                        <div className="grid-ibx__item h-300">
                                            <div className="grid-ibx__item-inner">

                                                <div className="grid-ibx__icon-wrp">

                                                    <StaticImage src="../../gatsbyimg/integrations/deribit-white.png"
                                                                 className="partners_carousel-img"
                                                                 alt=""
                                                                 width={"200"}
                                                                 title=""/>
                                                </div>

                                                <div className="grid-ibx__desc-wrp">

                                                    <div className="services_box services_box--integrations sb--hasicon">
                                                        <div className="services_box__inner clearfix">
                                                            <div className="services_box__content">
                                                                <div className="services_box__list-wrapper">
                                                                    <span className="services_box__list-bg"></span>
                                                                    <ul className="services_box__list">
                                                                        <li>
                                                                            <span className="services_box__list-text">Futures trading</span>
                                                                        </li>

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        <div className="grid-ibx__item h-300">
                                            <div className="grid-ibx__item-inner">

                                                <div className="grid-ibx__icon-wrp">

                                                    <StaticImage src="../../gatsbyimg/integrations/bitmex.svg"
                                                                 className="partners_carousel-img"
                                                                 alt=""
                                                                 width={"200"}
                                                                 title=""/>
                                                </div>

                                                <div className="grid-ibx__desc-wrp">

                                                    <div className="services_box services_box--integrations sb--hasicon">
                                                        <div className="services_box__inner clearfix">
                                                            <div className="services_box__content">
                                                                <div className="services_box__list-wrapper">
                                                                    <span className="services_box__list-bg"></span>
                                                                    <ul className="services_box__list">
                                                                        <li>
                                                                            <span className="services_box__list-text">Futures trading</span>
                                                                        </li>

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid-ibx__item h-300">
                                            <div className="grid-ibx__item-inner">

                                                <div className="grid-ibx__icon-wrp">

                                                    <StaticImage src="../../gatsbyimg/integrations/kucoin.svg"
                                                                 className="partners_carousel-img"
                                                                 alt=""
                                                                 width={"200"}
                                                                 title=""/>
                                                </div>

                                                <div className="grid-ibx__desc-wrp">

                                                    <div className="services_box services_box--integrations sb--hasicon">
                                                        <div className="services_box__inner clearfix">
                                                            <div className="services_box__content">
                                                                <div className="services_box__list-wrapper">
                                                                    <span className="services_box__list-bg"></span>
                                                                    <ul className="services_box__list">
                                                                        <li>
                                                                            <span className="services_box__list-text">Futures trading</span>
                                                                        </li>

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </section>
        );
    }
}

export default IntegrationsTable
;