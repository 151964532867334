import {lsProvider} from "./LightStreamerProvider";
import React, {Component} from "react";
import {Subscription} from "lightstreamer-client-web";
import StockTable from "./StockTable";


export class Stock extends Component {
    constructor(props) {
        super(props);
        this.lsProvider = lsProvider;


        this.state = {
            'ask': '-',
            'bid': '-',
            'color': 'btn-secondary'
        }

    }

    componentDidMount() {


        let fields = this.props.fields.map(fieldDef => {
            return fieldDef.field
        });
        this.subscription = new Subscription("MERGE", [this.props.item.subscription], fields);
        this.subscription.setDataAdapter("TICKS_ADAPTER");
        this.subscription.setRequestedSnapshot("no");
        this.subscription.addListener(this);

        this.lsProvider.getClient().subscribe(this.subscription);


    }


    componentWillUnmount() {
        this.lsProvider.getClient().unsubscribe(this.subscription);
    }

    onSubscriptionError(obj) {
        console.log("onSubscriptionError=" + JSON.stringify(obj));

    }

    onSubscription(obj) {
        console.log("onSubscription=" + JSON.stringify(obj));

    }

    onItemUpdate(obj) {

        let state = this.state;

        let nextState = {};

        let ask = obj.getValue('ask');
        let bid = obj.getValue('bid');
        nextState['ask'] = ask;
        nextState['bid'] = bid;

        let class_green = 'btn-fullsuccess';
        let class_gray = 'lined-gray';
        let class_red = 'btn-fullcolor';

        if (this.state['ask'] !== undefined) {
            if (this.state['ask'] > ask) {
                nextState['ask_class'] = class_red;

            } else if (this.state['ask'] < ask) {
                nextState['ask_class'] = class_green;

            } else {
                nextState['ask_class'] = class_gray;

            }
        } else {
            nextState['ask_class'] = class_gray;
        }

        if (this.state['bid'] !== undefined) {
            if (this.state['bid'] > bid) {
                nextState['bid_class'] = class_red;

            } else if (this.state['bid'] < bid) {
                nextState['bid_class'] = class_green;

            } else {
                nextState['bid_class'] = class_gray;

            }
        } else {
            nextState['bid_class'] = class_gray;
        }

        this.setState(nextState);
    };

    render() {
        let state = this.state;

        return (

            <tr key={this.props.item.market}>
                <td className={"fs-small fw-bold vertical-middle"}>{this.props.item.market}</td>
                {
                    this.props.fields.map(function (fieldDef) {
                        let classN = fieldDef.field + "_class";

                        return (
                            <td key={fieldDef.field}>
                                <span data-field={fieldDef.field} style={{cursor: 'default', width: 100}}
                                      className={state[classN] + " btn-element btn btn-skewed  btn-lined"}>{state[fieldDef.field]}</span>
                            </td>
                        );
                    })
                }

            </tr>
        );
    };


}


export default Stock;





