import React, {Component} from "react";
import {Stock} from "./Stock";


export class StockList extends Component {
    render () {
        let fields = this.props.fields;
        return (
            <tbody>
            {
                this.props.items.map(function (item) {
                    // console.log("Preparing to stream "+JSON.stringify(item) + " with fields="+fields);

                    return (
                        <Stock key={JSON.stringify(item)} item={item} fields={fields} />
                    );
                })
            }
            </tbody>
        );
    }
}
