import {LightstreamerClient, Subscription} from "lightstreamer-client-web";

class LightStreamerProvider {

    constructor() {
        this.getClient = this.getClient.bind(this);
        this.lsClient = new LightstreamerClient('https://stream-public.bitspreader.com', 'MARKETDATA');
        this.lsClient.connectionDetails.setUser("WEB2");
        // this.lsClient.connectionSharing.enableSharing("DemoCommonConnection", "ATTACH", "CREATE");

        this.lsClient.addListener({
            onStatusChange: function(newStatus) {
                console.log("LS on status change to="+newStatus);
            },
            onServerError: function (errorCode, errorMessage) {
                console.log("LS on error code="+errorCode+", message="+errorMessage);

            }

        });
        this.lsClient.connect();
    }

    getClient() {
        return this.lsClient;
    }



}

export const lsProvider = new LightStreamerProvider();
