import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";

class Integrations extends React.Component {
    render() {
        return (
            <section className="hg_section bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="partners_carousel clearfix">
                                <div className="row">
                                    <div className="col-sm-12 col-md-3 col-lg-2 mb-sm-20">
                                        <h5 className="title">
                                            <span>INTEGRATIONS</span>
                                        </h5>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-lg-3 col-md-3 col-sm-12"}></div>
                                    <div className="col-lg-2 col-md-2 col-sm-12 pt-10 text-center">
                                        <StaticImage src="../../gatsbyimg/integrations/binancefutures.png"
                                                     className="partners_carousel-img"
                                                     width={200}
                                                     alt="Binance"
                                                     title="Binance"/>
                                    </div>

                                    <div className="col-lg-2 col-md-2 col-sm-12  pt-10 text-center">
                                        <StaticImage src="../../gatsbyimg/integrations/deribit.png"
                                                     className="partners_carousel-img" alt="deribit"
                                                     width={200}
                                                     title="deribit"/>
                                    </div>

                                    <div className="col-lg-2 col-md-2 col-sm-12  pt-10 text-center">
                                        <StaticImage src="../../gatsbyimg/integrations/huobi-white.png"
                                                     className="partners_carousel-img"
                                                     width={200}
                                                     alt="huobi"
                                                     title="huobi"/>
                                    </div>
                                    <div className={"col-lg-3 col-md-3 col-sm-12"}></div>
                                </div>
                                <div className={"row pt-50"}>
                                    <div className={"col-lg-3 col-md-3 col-sm-12"}></div>
                                    <div className="col-lg-2 col-md-2 col-sm-12  pt-10 text-center my-auto">
                                        <StaticImage src="../../gatsbyimg/integrations/bitmex.svg"
                                                     className="partners_carousel-img"
                                                     width={200}
                                                     alt="bitmex"
                                                     title="bitmex"/>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-12  pt-10 text-center my-auto">
                                        <StaticImage src="../../gatsbyimg/integrations/kucoin.svg"
                                                     className="partners_carousel-img"
                                                     width={200}
                                                     alt="kucoin"
                                                     title="kucoin"/>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-12  pt-10 text-center my-auto">
                                        <StaticImage src="../../gatsbyimg/integrations/kraken.png"
                                                     className="partners_carousel-img"
                                                     width={200}
                                                     alt="kraken"
                                                     title="kraken"/>
                                    </div>
                                    <div className={"col-lg-3 col-md-3 col-sm-12"}></div>

                                </div>
                                <div className={"row pt-50"}>
                                    <div className={"col-lg-3 col-md-3 col-sm-12"}></div>
                                    <div className="col-lg-2 col-md-2 col-sm-12  pt-10 text-center my-auto">
                                        <StaticImage src="../../gatsbyimg/integrations/okx.svg"
                                                     className="partners_carousel-img"
                                                     width={200}
                                                     alt="OKX"
                                                     title="OKX"/>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-12  pt-10 text-center my-auto">
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-12  pt-10 text-center my-auto">
                                    </div>
                                    <div className={"col-lg-3 col-md-3 col-sm-12"}></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        )
            ;
    }
}

export default Integrations;