import * as React from "react"
import '../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../css/bootstrap.css";
import "../fonts/font-awesome/css/font-awesome.min.css";
import "../css/template.css";
import "../css/responsive.css";
import "../css/base-sizing.css";
import "../css/custom.css";

import HeaderAndMenu from "../page_components/common/HeaderAndMenu";
import Footer from "../page_components/common/Footer";
import BsHelmet from "../page_components/common/BsHelmet";
import IntegrationsTable from "../page_components/integrations/IntegrationsTable";
import CommonHero from "../page_components/common/CommonHero";
import IntegrationsFeatures from "../page_components/integrations/IntegrationsFeatures";
import Integrations from "../page_components/home/Integrations";


const IndexPage = ({data}) => {
    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"Integrations"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHero title={"Integrations"}/>
                <IntegrationsFeatures/>
                <Integrations/>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default IndexPage
