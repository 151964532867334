import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import MarketFeed from "../marketfeed/MarketFeed";

class IntegrationsFeatures extends React.Component {
    render() {
        return (
            <section className="hg_section bg-white pt-80">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6">

                                    <div className="kl-iconbox kl-iconbox--fleft text-left">
                                        <div className="kl-iconbox__inner">

                                            <div className="kl-iconbox__icon-wrapper">
                                                <span className="kl-icon">
                                                    <StaticImage src={"../../gatsbyimg/integrations/icon-way.png"} width={"50"} height={"50"} placeholder={"none"}/>
                                                </span>
                                            </div>


                                            <div className="kl-iconbox__content-wrapper">

                                                <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">

                                                    <h3 className="kl-iconbox__title fs-m fw-normal gray2">
                                                        Smart order routing
                                                    </h3>
                                                </div>



                                                <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">

                                                    <p className="kl-iconbox__desc fs-xn gray">
                                                        Execute trading strategies using globally distributed services to get <strong>MAXIMUM</strong> performance.
                                                    </p>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div className="col-sm-12 col-md-6 col-lg-6">

                                    <div className="kl-iconbox kl-iconbox--fleft text-left">
                                        <div className="kl-iconbox__inner">

                                            <div className="kl-iconbox__icon-wrapper">
                                                <span className="kl-icon">
                                                    <StaticImage src={"../../gatsbyimg/integrations/icon-markets.png"} width={"50"}
                                                                 height={"50"} placeholder={"none"}/>
                                                </span>
                                            </div>



                                            <div className="kl-iconbox__content-wrapper">

                                                <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">

                                                    <h3 className="kl-iconbox__title fs-m fw-normal gray2">
                                                        Dozens of markets
                                                    </h3>
                                                </div>



                                                <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">

                                                    <p className="kl-iconbox__desc fs-xn gray">
                                                        Trade synthetic calendar spreads on either single or cross exchange markets on a single screen.
                                                    </p>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div className="col-sm-12 col-md-6 col-lg-6">

                                    <div className="kl-iconbox kl-iconbox--fleft text-left">
                                        <div className="kl-iconbox__inner">

                                            <div className="kl-iconbox__icon-wrapper">
                                                <span className="kl-icon">
                                                    <StaticImage src={"../../gatsbyimg/integrations/icon-speed.png"} width={"50"}
                                                                 height={"50"} placeholder={"none"}/>
                                                </span>
                                            </div>



                                            <div className="kl-iconbox__content-wrapper">

                                                <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">

                                                    <h3 className="kl-iconbox__title fs-m fw-normal gray2">
                                                        Speed & performance
                                                    </h3>
                                                </div>



                                                <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">

                                                    <p className="kl-iconbox__desc fs-xn gray">
                                                        Leverage our smart network infrastructure for cross-exchange connectivity to get maximum spead and trade using real time market data.
                                                    </p>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div className="col-sm-12 col-md-6 col-lg-6">

                                    <div className="kl-iconbox kl-iconbox--fleft text-left">
                                        <div className="kl-iconbox__inner">

                                            <div className="kl-iconbox__icon-wrapper">
                                                <span className="kl-icon">
                                                    <StaticImage src={"../../gatsbyimg/integrations/icon-viz.png"} width={"50"}
                                                                 height={"50"} placeholder={"none"}/>
                                                </span>
                                            </div>



                                            <div className="kl-iconbox__content-wrapper">

                                                <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">

                                                    <h3 className="kl-iconbox__title fs-m fw-normal gray2">
                                                        Visualizations
                                                    </h3>
                                                </div>



                                                <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">

                                                    <p className="kl-iconbox__desc fs-xn gray">
                                                        View data like depth of book, trades, volumes, orders and positions  for multiple products across mutliple exchanges all at once.(sourced from TT)
                                                    </p>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </section>
        );
    }
}

export default IntegrationsFeatures
;